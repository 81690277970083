import { ComponentType } from "react";
import AggregatedDrivers from "./AggregatedDriver";
import Attendants from "./Attendant";
import Chains from "./Chain";
import ChainEmployees from "./ChainEmployee";
import Chats from "./Chat";
import Cities from "./City";
import CompanyEmployees from "./CompanyEmployee";
import Contracts from "./Contract";
import CostCenters from "./CostCenter";
import Customers from "./Customer";
import Drivers from './Driver';
import Fillins from "./Fillin";
import Fuels from "./Fuel";
import Bills from "./Bill/index";
import LinkedChains from "./LinkedChains";
import LinkedCompanies from "./LinkedCompanies";
import Managers from "./Manager";
import PushNotifications from "./Notification";
import OptCodes from "./OtpCodes";
import ParentFuels from "./ParentFuel";
import Partners from "./Partner";
import PermissionGroups from "./PermissionGroups/index";
import Permissions from "./Permissions/index";
import Profiles from "./Profiles/index";
import Ratings from "./Rating";
import FillinAttempt from "./FillinAttempt";
import Routes from "./Route";
import Rules from "./Rule";
import Services from "./Service";
import Stations from "./Station";
import Valets from "./Valet";
import Vehicles from "./Vehicle";
import VehicleBases from "./VehicleBase";
import VehicleGroups from "./VehicleGroup";
import Vouchers from "./Voucher";
import SubCompanies from "./SubCompany";
import Companies from "./Company";
import Operations from "./Operation";

export interface Resource {
    name: string;
    permissions: string[];
    list?: ComponentType | ComponentWithPermissions;
    create?: ComponentType | ComponentWithPermissions;
    edit?: ComponentType | ComponentWithPermissions;
    show?: ComponentType | ComponentWithPermissions;
}

const containers: Array<Resource> = [
    Drivers,
    PermissionGroups,
    Permissions,
    Profiles,
    ChainEmployees,
    CompanyEmployees,
    CostCenters,
    Chains,
    Services,
    AggregatedDrivers,
    Attendants,
    Cities,
    Customers,
    Fuels,
    Managers,
    PushNotifications,
    OptCodes,
    ParentFuels,
    Partners,
    Ratings,
    Routes,
    Rules,
    Stations,
    SubCompanies,
    Companies,
    Chats,
    Valets,
    Vehicles,
    VehicleBases,
    VehicleGroups,
    Vouchers,
    Contracts,
    Bills,
    Fillins,
    LinkedCompanies,
    LinkedChains,
    FillinAttempt,
    SubCompanies,
    Companies,
    Operations,
];

export default containers;