import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, useRecordContext } from 'react-admin';
import { CircleStatus, ColoredDiferenceValueField, CustomizableDatagrid, LazyLoadAutoCompleteInput, ListPagination, Permission, ReportList } from '../../components';
import { PERMISSIONS } from "../../constants";
import { formatCpf } from "../../utils/utils";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const DriverAverageList = () => {
  const classes = useStyles();
  const props = useListContext();

  const AveragePanel = () => {
    const record = useRecordContext();
    const data = record.vehicleAverages;

    return (
      <Datagrid data={data} total={data.length} bulkActionButtons={false}>
        <ReferenceField label="Veículo" reference="vehicles" source="id" sortable={false}>
          <TextField source="licensePlate" />
        </ReferenceField>
        <NumberField
          source="amount"
          label="Volume Gasto (litros)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="value"
          label="Valor Total"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="expectedAverage"
          label="Média ideal (Km/l)"
          textAlign="center"
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          sortable={false}
        />
        <NumberField
          source="average"
          label="Média Real (Km/l)"
          textAlign="center"
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          sortable={false}
        />
        <FunctionField
          label="Eficiência (%)"
          sortBy="averageEfficiency"
          textAlign="center"
          render={record => (<CircleStatus value={record.averageEfficiency} emptyText="--" />)}
          sortable={false}
        />
        <ColoredDiferenceValueField
          source="diffValue"
          label="Diferença (R$)"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid >
    );
  };

  return (
    <>
      <Typography className={classes.title}>Médias por Motorista</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['id', 'cpf', 'cnh', 'totalValue', 'totalAmount', 'averageEfficiency', 'diffValue']}
        expand={<AveragePanel />}
        bulkActionButtons={false}
        isRowExpandable={row => row.vehicleAverages}
      >
        <ReferenceField
          label="Motorista"
          emptyText="Motorista não identificado"
          source="id"
          basePath="drivers"
          reference="drivers"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="cpf" label="CPF" textAlign="center" />
        <TextField source="cnh" label="CNH" textAlign="center" emptyText="Não informado" />
        <NumberField
          source="totalAmount"
          label="Volume Gasto (litros)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="totalValue"
          label="Valor Total"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <FunctionField
          label="Eficiência (%)"
          sortBy="averageEfficiency"
          textAlign="center"
          render={record => (<CircleStatus value={record.averageEfficiency} emptyText="--" />)}
        />
        <ColoredDiferenceValueField
          source="diffValue"
          label="Diferença (R$)"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ generatedReportAt: new Date(), ...filterValues }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permission permission={PERMISSIONS.ADMIN} label="Grupo de Transportadora" textAlign="center">
        <ReferenceField
          source="companyId"
          reference="companies"
          sort={{ field: "name", order: "ASC" }}
          emptyText="Todos"
        >
          <TextField
            source={'name'}
          />
        </ReferenceField>
      </Permission>,
      <ReferenceField
        label="Motorista"
        source="driverId"
        reference="drivers"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        textAlign="center"
      >
        <TextField
          source={'name'}
        />
      </ReferenceField>,
      <DateField source="generatedReportAt" emptyText="" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
};

const DriverAveragesReport = (props) => {
  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <Permission permission={PERMISSIONS.ADMIN} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        style={{ width: 225 }}
      >
        <SelectInput
          label="Grupo de Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <FormDataConsumer alwaysOn>
      {({ formData }) => (
        <LazyLoadAutoCompleteInput
          label="Motorista"
          reference="drivers"
          source="driverId"
          format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
          style={{ minWidth: 160, marginBottom: 4 }}
          record={formData}
          size={"small"}
        />
      )}
    </FormDataConsumer>,
  ];

  return (
    <ReportList
      {...props}
      basePath="drivers"
      title="Médias por Motorista"
      resource="reports/driver-averages"
      filters={listFilters}
      sort={{ field: 'averageEfficiency', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().startOf('day').subtract(1, 'month').toISOString(),
        to: moment().endOf('day').toISOString(),
        companyId: localStorage.getItem('companyId'),
      }}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={false}
      titleOnPrint="Relatório de médias"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <DriverAverageList />
    </ReportList>
  );
};

export default DriverAveragesReport;