import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { BooleanField, BooleanInput, ChipField, Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useGetIdentity, useListContext } from 'react-admin';
import { CustomizableDatagrid, FillinStatusField, LazyLoadAutoCompleteInput, ListPagination, Permission, Permissions, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { formatCpf, getChainId, getCompanyId } from '../../utils/utils';
import { FillinReviewStatus, FillinStatus } from '../../models';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const FillinList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  return (
    <>
      <Typography className={classes.title}>Abastecimentos</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['stationId', 'contractId', 'value', 'amount', 'pricePerLiter', 'oldPricePerLiter', 'fuelId', 'receivedAt', 'deliveryId', 'invoiceId', 'status']}
        bulkActionButtons={false}
      >
        <ReferenceField
          source="stationId"
          basePath="stations"
          reference="stations"
        >
          <TextField
            label="Posto"
            emptyText="Posto não identificado"
            source="name" />
        </ReferenceField>
        <Permissions label="Rede" emptyText="Não identificada" userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField source="chainId" basePath="chains" reference="chains" link={null}>
            <TextField label="Rede" emptyText="Não identificada" source="name" />
          </ReferenceField>
        </Permissions>
        <Permissions emptyText="Não identificado" label="Frentista" userRoles={[UserRole.admin, UserRole.chain]}>
          <ReferenceField source="attendantId" basePath="station-attendants" reference="station-attendants" link="show">
            <TextField emptyText="Não identificado" label="Frentista" source="name" />
          </ReferenceField>
        </Permissions>
        <ReferenceField label="Veículo" emptyText="Não identificado" source="vehicleId" basePath="vehicles" reference="vehicles" link="show">
          <TextField label="Veículo" emptyText="Não identificado" source="licensePlate" />
        </ReferenceField>
        <ReferenceField label="Motorista" emptyText="Não identificado" source="driverId" basePath="drivers" reference="drivers" link="show">
          <TextField label="Motorista" emptyText="Não identificado" source="name" />
        </ReferenceField>
        <TextField label="Odômetro" source="odometer" />
        <ReferenceField source="contractId" reference="contracts" label="Contrato" textAlign="center" emptyText="--" link={false}>
          <TextField source="code" />
        </ReferenceField>
        <NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="pricePerLiter"
          label="Preço com desconto"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="oldPricePerLiter"
          label="Preço normal"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <FunctionField
          label="Desconto"
          textAlign="center"
          render={record =>
            `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0)
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        />
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuelId"
          basePath="fuels"
          reference="fuels"
          link={identity?.role === UserRole.admin && "show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />
        <DateField source="receivedAt" label="Data" showTime locales="pt-BR" />
        <BooleanField source="invoiceId" label="Nota Fiscal" looseValue />
        <ReferenceField
          label="Aprovado Por"
          emptyText="--"
          source="approvedBy"
          basePath="company-employees"
          reference="company-employees"
        >
          <TextField source="name" />
        </ReferenceField>
        <FillinStatusField label="Status" />
        <DateField source="approvedAt" label="Aprovado em" emptyText="--" showTime locales="pt-BR" />
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permissions userRoles={[UserRole.admin, UserRole.chain]} label="Postos">
        <ReferenceArrayField emptyText="Todos" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motorista">
        <ReferenceField emptyText="Todos" source="driverId" textAlign="center" basePath="drivers" reference="drivers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motoristas Agregados" >
        <BooleanField source="isAggregated" />
      </Permissions>
      <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />
      <FunctionField source="hasInvoice" label="Fatura" render={record => record.hasInvoice === true ? 'Com Fatura' : record.hasInvoice === false ? 'Sem Fatura' : 'Todos'} />
      <FunctionField source="manuallyAdded" label="Tipo" render={record => record.manuallyAdded === true ? 'Contingência' : record.manuallyAdded === false ? 'Automático' : 'Todos'} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const FillinReport = (props) => {
  const { identity } = useGetIdentity();

  const filterValue = identity?.role === UserRole.chain ? { chainId: getChainId() }
    : (identity?.role === UserRole.company ? { companyId: getCompanyId() } : {});

  filterValue.stationReviewStatus = FillinReviewStatus.approved;

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    < DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.chain]} alwaysOn>
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
      >
        <SelectArrayInput
          style={{ marginBottom: 4, paddingTop: 8 }}
          label="Posto"
          optionText="name"
        />
      </ReferenceArrayInput>
    </Permissions>,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
        filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
      >
        <SelectInput
          optionText={'name'}
          label="Filial"
          emptyText="Todos"
        />
      </ReferenceInput>
    </Permissions>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 650, justifyContent: 'space-between' }}>
        <FormDataConsumer>
          {({ formData }) => (
            <LazyLoadAutoCompleteInput
              label="Motorista"
              reference="drivers"
              source="driverId"
              format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
              style={{ minWidth: 400, marginBottom: 4 }}
              record={formData}
              size={"small"}
              filter={{ isAggregated: formData.isAggregated || false }}
            />
          )}
        </FormDataConsumer>
        <BooleanInput source='isAggregated' label={'Motoristas Agregados'} defaultValue={false} />
      </Box>
    </Permissions>,
    <ReferenceInput label="Produto" source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
      <SelectInput
        emptyText="Todos"
        label="Produto"
        optionText={'name'}
      />
    </ReferenceInput>,
    <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />,
    <SelectInput
      source="hasInvoice"
      label="Fatura"
      choices={[
        { name: "Sem nota fiscal", value: false },
        { name: "Com nota fiscal", value: true },
      ]}
      emptyText="Todos"
      optionText="name"
      optionValue="value"
      allowEmpty
      alwaysOn
    />,
    <SelectInput
      label="Tipo"
      source="manuallyAdded"
      choices={[
        { name: "Contingência", value: true },
        { name: "Automático", value: false }
      ]}
      emptyText="Todos"
      optionText="name"
      optionValue="value"
      allowEmpty
      alwaysOn
    />,
  ];

  const fillinsExporter = async (data, fetchRelatedRecords) => {
    let fillinInfo = data;

    const fetchPromises = {
      fuels: () => fetchRelatedRecords(fillinInfo, 'fuelId', 'fuels'),
      stations: () => fetchRelatedRecords(fillinInfo, 'stationId', 'stations'),
      chains: () => fetchRelatedRecords(fillinInfo, 'chainId', 'chains'),
      vehicles: () => fetchRelatedRecords(fillinInfo, 'vehicleId', 'vehicles'),
      drivers: () => fetchRelatedRecords(fillinInfo, 'driverId', 'drivers'),
      subCompanies: () => fetchRelatedRecords(fillinInfo, 'subCompanyId', 'sub-companies'),
      companyEmployees: () => fetchRelatedRecords(fillinInfo, 'approvedBy', 'company-employees'),
      ...(identity.role === UserRole.chain ? {
        attendants: () => fetchRelatedRecords(fillinInfo, 'attendantId', 'station-attendants'),
      } : {}),
    };

    const promises = Object.entries(fetchPromises).map(([key, fn]) => fn().then(result => [key, result]));
    const fetchData = Object.fromEntries(await Promise.all(promises));

    fillinInfo = fillinInfo.map(fillin => {
      const { chainId, fuelId, stationId, driverId, subCompanyId, attendantId, vehicleId, approvedBy, ...fillinRest } = fillin;
      return {
        posto: stationId ? fetchData['stations'][stationId].name : 'Não identificado',
        rede: chainId ? fetchData['chains'][chainId].name : 'Não identificado',
        veiculo: vehicleId ? fetchData['vehicles'][vehicleId].licensePlate : 'Não identificado',
        motorista: driverId ? fetchData['drivers'][driverId].name : 'Não identificado',
        transportadora: subCompanyId ? fetchData['subCompanies'][subCompanyId].name : 'Não identificado',
        ...(identity.role === UserRole.chain ? {
          frentista: attendantId ? fetchData['attendants'][attendantId].name : 'Não identificado',
        } : {}),
        odometro: fillinRest.odometer,
        valor: fillinRest.value,
        litros: fillinRest.amount,
        preco_do_acordo: Number(fillinRest.pricePerLiter).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        preco_do_bico: Number(fillinRest.oldPricePerLiter).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        desconto: Number(fillinRest.oldPricePerLiter && fillinRest.pricePerLiter ? (fillinRest.oldPricePerLiter - fillinRest.pricePerLiter) * (fillinRest.amount || 0) : 0)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        combustivel: fuelId ? fetchData['fuels'][fuelId].name : 'Não identificado',
        identificador: fillinRest.status === "approved" ? fillinRest.deliveryId : '',
        data: fillinRest.receivedAt ? dayjs(fillinRest.receivedAt).format('DD/MM/YYYY HH:mm') : '',
        nota_fiscal: fillinRest.invoiceId ? 'sim' : 'não',
        status: FillinStatus.translateStatus(fillinRest.status),
        ...(identity.role === UserRole.company ? {
          aprovadoPor: approvedBy ? fetchData['companyEmployees'][approvedBy].name : '',
          aprovadoEm: fillinRest.approvedAt ? dayjs(fillinRest.approvedAt).format('DD/MM/YYYY HH:mm') : '',
        } : {}),
      };
    });

    return { data: fillinInfo, fileName: 'relatorio_de_abastecimentos' };
  }

  return (
    <ReportList
      {...props}
      basePath="fillins"
      title="Abastecimentos"
      resource="fillins"
      filters={listFilters}
      sort={{ field: 'receivedAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().subtract(1, 'month').startOf('day').toISOString(),
        to: moment().endOf('day').toISOString(),
        isAggregated: false,
      }}
      filter={filterValue}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={fillinsExporter}
      exporterXLSX
      titleOnPrint="Relatório de Abastecimentos"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <FillinList />
    </ReportList>
  );
};

export default FillinReport;