import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { ChipField, Datagrid, DateField, DateInput, FormDataConsumer, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useGetIdentity, useListContext, usePermissions } from 'react-admin';
import { ListPagination, Permissions, ReportList } from '../../components';
import { getCompanyId } from '../../utils/utils';
import { PERMISSIONS } from '../../constants';
import { UserRole } from '../../providers/authProvider';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const PriceChangeList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  return (
    <>
      <Typography className={classes.title}>Alterações de Preços</Typography>
      <Datagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['stationId', 'oldPrice', 'price', 'fuelId', 'updatedAt']}
        bulkActionButtons={false}
      >
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuelId"
          basePath="fuels"
          reference="fuels"
          link={identity?.role === UserRole.admin && "show"}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Posto"
          emptyText="Posto não identificado"
          source="stationId"
          basePath="stations"
          reference="stations"
          link={identity && [UserRole.admin, UserRole.chain].includes(identity.role) && "show"}
          textAlign="center"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="oldPrice"
          label="Preço antigo"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="price"
          label="Preço novo"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="updatedAt" label="Alterado ás" showTime locales="pt-BR" textAlign="center" />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField label="Rede" emptyText="Todos" source="chainId" textAlign="center" basePath="chains" reference="chains" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField emptyText="Todos" source="stationIds" label="Postos" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField emptyText="Todos" source="fuelIds" label="Combustíveis" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const PriceChangesReport = (props) => {

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <ReferenceInput
      label="Rede"
      source="chainId"
      reference="chains"
      sort={{ field: "name", order: "ASC" }}
      filter={{ companyId: getCompanyId(), onlyWithContract: true }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        emptyText="Todos"
        label="Rede"
        optionText={'name'}
      />
    </ReferenceInput>,
    <FormDataConsumer alwaysOn>
      {({ formData }) => (
        <ReferenceArrayInput
          source="stationIds"
          reference="stations"
          sort={{ field: "name", order: "ASC" }}
          filter={formData.chainId ? { chainId: formData.chainId } : {}}
          alwaysOn
        >
          <SelectArrayInput
            style={{ marginBottom: 4, paddingTop: 8 }}
            label="Posto"
            optionText="name"
            disabled={!formData.chainId}
          />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        style={{ marginBottom: 4, paddingTop: 8 }}
        label="Combustíveis"
        optionText="name"
      />
    </ReferenceArrayInput>,
  ];

  return (
    <Permissions permissions={[PERMISSIONS.REPORT_PRICE_CHANGES]}>
      <ReportList
        {...props}
        basePath="reports/price-changes"
        title="Alterações de Preço"
        resource="reports/price-changes"
        filters={listFilters}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          from: moment().subtract(1, 'month').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Alterações de Preço"
        filterTablePrint={<FilterTablePrint />}
        landscape={true}
      >
        <PriceChangeList />
      </ReportList>
    </Permissions>
  );
};

export default PriceChangesReport;