import React, { useCallback } from 'react';
import { Button, DataProvider, ExportButtonProps, fetchRelatedRecords, sanitizeListRestProps, useDataProvider, useListContext, useNotify, useResourceContext } from 'react-admin';
import DownloadIcon from '@mui/icons-material/GetApp';

type ExporterCustom = {
    data: any;
    fetchRelatedRecords: (
        data: any,
        field: string,
        resource: string
    ) => Promise<any>;
    dataProvider: DataProvider;
    resource?: string;
    filter: any;
}

interface ExportButtonCustomProps extends ExportButtonProps {
    exporter(exporterCustom: ExporterCustom): void;
}

const ExportButtonCustom: React.FC<ExportButtonCustomProps> = (props) => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = <DownloadIcon />,
        exporter: customExporter,
        ...rest
    } = props;
    const {
        filter,
        filterValues,
        sort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props);

    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleClick = useCallback(
        event => {
            dataProvider
                .getList(resource, {
                    sort,
                    filter: filter
                        ? { ...filterValues, ...filter }
                        : filterValues,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) =>
                        exporter &&
                        exporter({
                            data,
                            fetchRelatedRecords: fetchRelatedRecords(dataProvider),
                            filter: filter ? { ...filterValues, ...filter } : filterValues,
                            dataProvider,
                            resource
                        })
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            sort,
            dataProvider,
            exporter,
            filter,
            filterValues,
            maxResults,
            notify,
            onClick,
            resource,
            sort,
        ]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            {...sanitizeListRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

export default ExportButtonCustom;