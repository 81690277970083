import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Button, Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, WrapperField, useGetIdentity, useListContext, useRecordContext } from 'react-admin';
import { CustomizableDatagrid, LazyLoadAutoCompleteInput, ListPagination, Permission, Permissions, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { exportToXLSX, formatCpf, formatNumber, getChainId, getCompanyId } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const FillinList = () => {
  const classes = useStyles();
  const props = useListContext();

  const { identity } = useGetIdentity();

  const DownloadFile = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }

    const handleDownloadFile = (url) => {
      try {
        saveAs(url, `${url.split('/').slice(-1)}`);
      } catch (error) {
        notify(`Erro ao baixar arquivo`, { type: 'warning' });
      }
    };

    return (
      record.path ?
        <Button
          {...props}
          onClick={(e) => {
            e.stopPropagation();
            handleDownloadFile(record.path);
          }}
        />
        : <></>
    )
  }

  return (
    <>
      <Typography className={classes.title}>Transações</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['stationId', 'value', 'amount', 'pricePerLiter', 'oldPricePerLiter', 'fuelId', 'createdAt',]}
        bulkActionButtons={false}
      >
        <ReferenceField label="Grupo" source="invoiceBaseId" reference="vehicle-bases">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Cliente" source="invoiceBaseId" reference="vehicle-bases">
          <TextField source="code" />
        </ReferenceField>
        <ReferenceField label="CNPJ Cliente" source="invoiceBaseId" reference="vehicle-bases">
          <TextField source="cnpj" />
        </ReferenceField>
        <ReferenceField label="Placa" source="vehicleId" emptyText="Não identificado" reference="vehicles" link="show">
          <TextField source="licensePlate" />
        </ReferenceField>
        <ReferenceField label="Modelo" source="vehicleId" emptyText="Não identificado" reference="vehicles" link="show">
          <TextField source="model" />
        </ReferenceField>
        <ReferenceField label="Responsavel" source="vehicleId" reference="vehicles">
          <ReferenceField label="Responsavel" source="managerId" reference="managers">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <DateField source="receivedAt" label="Data" locales="pt-BR" />
        <DateField source="receivedAt" label="Hora" showTime showDate={false} locales="pt-BR" />
        <NumberField source="numericId" label="Transação" />
        <ReferenceField label="Título" source="billId" emptyText="Não identificado" reference="bills">
          <TextField source="billNumber" />
        </ReferenceField>
        <ReferenceField label="Vencimento Título" source="billId" emptyText="Não identificado" reference="bills">
          <DateField source="billExpiration" locales="pt-BR" />
        </ReferenceField>
        <ReferenceField
          label="Motorista"
          source="driverId"
          reference="drivers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Matrícula"
          source="driverId"
          reference="drivers"
          link={false}
        >
          <TextField source="register" />
        </ReferenceField>
        <ReferenceField
          label="CNPJ"
          source="stationId"
          reference="stations"
        >
          <TextField source="cnpj" />
        </ReferenceField>
        <ReferenceField
          label="Estabelecimento"
          source="stationId"
          reference="stations"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Quilometragem" source="odometer" />
        <ReferenceField
          label="Serviço"
          source="fuelId"
          basePath="fuels"
          reference="fuels"
          link={identity?.role === UserRole.admin && "show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Nota Fiscal" source="invoiceId" emptyText="Não identificado" reference="invoices">
          <TextField source="invoiceNumber" />
        </ReferenceField>
        <ReferenceField label="CNPJ FILIAL" source="invoiceBaseId" reference="vehicle-bases">
          <TextField source="cnpj" />
        </ReferenceField>
        <ReferenceField label="Serie" source="invoiceBaseId" reference="vehicle-bases">
          <TextField source="serie" />
        </ReferenceField><NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <FunctionField label="Km Rodados" render={record => record.lastOdometer ? record.odometer - record.lastOdometer : '-'} />
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <FunctionField label="Km/litros" render={record => (record.lastOdometer && record.odometer - record.lastOdometer) !== 0 ? formatNumber((record.odometer - record.lastOdometer) / record.amount, { maximumFractionDigits: 2 }) : 0} />
        <NumberField
          source="pricePerLiter"
          label="Valor/Litros"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <ReferenceField label="Centro de Custo" source="vehicleId" reference="vehicles">
          <ReferenceField label="Centro de Custo" source="costCenterId" reference="cost-centers">
            <TextField source="identifier" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField label="Valor Total XML" source="billId" reference="bills">
          <TextField source="billValue" />
        </ReferenceField>
        <ReferenceField label="Nota Fiscal 1" source="invoiceId" emptyText="Não identificado" reference="invoices">
          <TextField source="invoiceNumber" />
        </ReferenceField>
        <WrapperField>
          <ReferenceField reference="invoices" source="invoiceId">
            <DownloadFile label="XML" />
          </ReferenceField>
        </WrapperField>
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permissions userRoles={[UserRole.admin, UserRole.chain]} label="Posto">
        <ReferenceField emptyText="Todos" source="stationId" textAlign="center" reference="stations" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motorista">
        <ReferenceField emptyText="Todos" source="driverId" textAlign="center" reference="drivers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="numericId" label="Identificador" />
      <FunctionField source="hasInvoice" label="Nota Fiscal" render={record => record.hasInvoice === true ? 'Com Nota Fiscal' : record.hasInvoice === false ? 'Sem Nota Fiscal' : 'Todos'} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const FinancialReport = (props) => {
  const { identity } = useGetIdentity();
  const filterValue = (identity?.role === UserRole.company ? { companyId: getCompanyId(), generateFinancialMovement: true } : { generateFinancialMovement: true });

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.chain]} alwaysOn>
      <ReferenceInput
        label="Posto"
        source="stationId"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
      >
        <SelectInput
          emptyText="Todos"
          label="Posto"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permissions>,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
        filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
      >
        <SelectInput
          optionText={'name'}
          label="Filial"
          emptyText="Todos"
        />
      </ReferenceInput>
    </Permissions>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <FormDataConsumer>
        {({ formData }) => (
          <LazyLoadAutoCompleteInput
            label="Motorista"
            reference="drivers"
            source="driverId"
            format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
            style={{ minWidth: 160, marginBottom: 4 }}
            record={formData}
            size={"small"}
          />
        )}
      </FormDataConsumer>
    </Permissions>,
    <ReferenceInput label="Produto" source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
      <SelectInput
        emptyText="Todos"
        label="Produto"
        optionText={'name'}
      />
    </ReferenceInput>,
    <SelectInput
      source="hasInvoice"
      emptyText="Todos"
      label="Nota Fiscal"
      choices={[
        { name: "Sem nota fiscal", value: false },
        { name: "Com nota fiscal", value: true },
      ]}
      optionText="name"
      optionValue="value"
      allowEmpty
      alwaysOn
    />,
  ];

  const fillinsExporter = async (data, fetchRelatedRecords) => {
    let fillinInfo = data;

    const fetchPromises = [
      fetchRelatedRecords(fillinInfo, 'fuelId', 'fuels'),
      fetchRelatedRecords(fillinInfo, 'stationId', 'stations'),
      fetchRelatedRecords(fillinInfo, 'driverId', 'drivers'),
      fetchRelatedRecords(fillinInfo, 'vehicleId', 'vehicles'),
      fetchRelatedRecords(fillinInfo, 'invoiceBaseId', 'vehicle-bases'),
      fetchRelatedRecords(fillinInfo.map(f => f.vehicle), 'vehicleId', 'cost-centers'),
      fetchRelatedRecords(fillinInfo.map(f => f.vehicle), 'managerId', 'managers'),
      fetchRelatedRecords(fillinInfo, 'invoiceId', 'invoices'),
    ];

    const fetchData = await Promise.all(fetchPromises);
    const fuels = fetchData[0];
    const stations = fetchData[1];
    const drivers = fetchData[2];
    const vehicles = fetchData[3];
    const invoiceBases = fetchData[4];
    const costCenters = fetchData[5];
    const managers = fetchData[6];
    const invoices = fetchData[7];

    fillinInfo = fillinInfo.map(fillin => {
      const { fuelId, stationId, driverId, invoiceId, subCompanyId, valetId, attendantId, invoiceBaseId, vehicleId, vehicle, ...fillinRest } = fillin;
      return {
        "Grupo": invoiceBaseId && invoiceBases[invoiceBaseId].name ? invoiceBases[invoiceBaseId].name.toUpperCase() : '',
        "Cliente": invoiceBaseId ? invoiceBases[invoiceBaseId].code : '',
        "CNPJ Cliente": invoiceBaseId ? `${invoiceBases[invoiceBaseId].cnpj}\t` : '',
        "Cartao": '',
        "Placa": vehicleId && vehicles[vehicleId] ? vehicles[vehicleId].licensePlate : '',
        "Modelo": vehicleId && vehicles[vehicleId] && vehicles[vehicleId].model ? vehicles[vehicleId].model.toUpperCase() : '',
        "Responsavel": vehicle && vehicle.managerId && managers[vehicle.managerId] && managers[vehicle.managerId].name ? managers[vehicle.managerId].name.toUpperCase() : '',
        "Data": moment(fillinRest.createdAt).format('DD/MM/YYYY'),
        "Hora": moment(fillinRest.createdAt).format('HH:mm:ss'),
        "Transacao": fillinRest.numericId,
        "Titulo": invoiceId && invoices[invoiceId] ? invoices[invoiceId].billNumber : '',
        "Vencimento Titulo": invoiceId && invoices[invoiceId] && invoices[invoiceId].billExpiration ? moment(invoices[invoiceId].billExpiration).format('DD/MM/YYYY') : '',
        "Lote": '',
        "Tipo": "COMPRA",
        "Liberacao Restricao": '',
        "Motorista": driverId && drivers[driverId].name ? drivers[driverId].name.toUpperCase() : '',
        "Matricula": driverId ? drivers[driverId].register : '',
        "CNPJ": stationId ? `${stations[stationId].cnpj}\t` : '',
        "Estabelecimento": stationId && stations[stationId].name ? stations[stationId].name.toUpperCase() : '',
        "Cidade": stationId ? `${stations[stationId].city}/${stations[stationId].state}`.toUpperCase() : '',
        "Quilometragem": fillinRest.odometer,
        "Horas": '',
        "Servico": fuelId && fuels[fuelId].name ? fuels[fuelId].name.toUpperCase() : '',
        "Nota Fiscal": invoiceId && invoices[invoiceId] ? invoices[invoiceId].invoiceNumber : '',
        "CNPJ FILIAL": fillinRest.invoiceCnpj,
        "Serie": invoiceId && invoices[invoiceId] && invoices[invoiceId].serie ? formatNumber(invoices[invoiceId].serie, { minimumIntegerDigits: 3 }) : '',
        "Data Emissao": invoiceId && invoices[invoiceId] && invoices[invoiceId].issueDate ? moment(invoices[invoiceId].issueDate).format('DD/MM/YYYY') : '',
        "Chave de Acesso NF": invoiceId && invoices[invoiceId] ? `${invoices[invoiceId].accessKey}\t` : '',
        "Valor": `${formatNumber(fillinRest.value, { minimumFractionDigits: 2 })}`,
        "Km Rodados": fillinRest.odometer - fillinRest.lastOdometer,
        "Horas Trabalhadas": '',
        "Litros": formatNumber(fillinRest.amount, { minimumFractionDigits: 2 }),
        "Km/litros": (fillinRest.odometer - fillinRest.lastOdometer) !== 0 ? formatNumber((fillinRest.odometer - fillinRest.lastOdometer) / fillinRest.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '0,00',
        "Litros/Horas": '',
        "Valor/Litros": formatNumber(fillinRest.pricePerLiter, { minimumFractionDigits: 3 }),
        "Emissora": '',
        "Centro de Custo": vehicle && vehicle.costCenterId && costCenters[vehicle.costCenterId] ? costCenters[vehicle.costCenterId].name : '',
        "Valor Total XML": invoiceId && invoices[invoiceId] && invoices[invoiceId].totalInvoiceValue ? formatNumber(invoices[invoiceId].totalInvoiceValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '',
        "InformacaoAdicional1Veiculo": vehicle && vehicle.costCenterId && costCenters[vehicle.costCenterId] ? costCenters[vehicle.costCenterId].name : '',
        "InformacaoAdicional2Veiculo": '',
        "InformacaoAdicional3Veiculo": '',
        "InformacaoAdicional4Veiculo": '',
        "InformacaoAdicional5Veiculo": '',
        "InformacaoAdicional1Motorista": '',
        "InformacaoAdicional2Motorista": '',
        "InformacaoAdicional3Motorista": '',
        "InformacaoAdicional4Motorista": '',
        "InformacaoAdicional5Motorista": '',
        "Numero Externo": '',
        "Nota Fiscal 1": invoiceId && invoices[invoiceId] ? invoices[invoiceId].invoiceNumber : '',
        "Nota Fiscal 2": '',
        "Nota Fiscal 3": '',
        "Nota Fiscal 4": '',
        "Nota Fiscal 5": '',
        "Nota Fiscal 6": '',
        "Nota Fiscal 7": '',
        "Nota Fiscal 8": '',
        "Nota Fiscal 9": '',
        "Nota Fiscal 10": '',
        "Chave de Acesso 1": invoiceId && invoices[invoiceId] ? `${invoices[invoiceId].accessKey}\t` : '',
        "Chave de Acesso 2": '',
        "Chave de Acesso 3": '',
        "Chave de Acesso 4": '',
        "Chave de Acesso 5": '',
        "Chave de Acesso 6": '',
        "Chave de Acesso 7": '',
        "Chave de Acesso 8": '',
        "Chave de Acesso 9": '',
        "Chave de Acesso 10": '',
      };
    });

    exportToXLSX(fillinInfo, 'Financeiro');
  }

  return (
    <ReportList
      {...props}
      basePath="fillins"
      title="Transações"
      resource="fillins"
      filters={listFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().subtract(1, 'month').startOf('day').toISOString(),
        to: moment().endOf('day').toISOString(),
      }}
      filter={filterValue}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={fillinsExporter}
      titleOnPrint="Relatório de Financeiro"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <FillinList />
    </ReportList>
  );
};

export default FinancialReport;