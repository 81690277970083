import { ContractProductMeasureType } from "./contractProductMeasureType";

export class ContractProduct {

  id?: string | undefined;
  contractId?: string | undefined;
  fuelId: string;
  measureType: ContractProductMeasureType;
  amount?: number | undefined;
  dealType?: string | undefined;
  dealValueType?: string | undefined;
  dealValue: number;
  dealMinValue?: number | undefined;

  constructor(props: ContractProduct) {
    this.id = props.id;
    this.contractId = props.contractId;
    this.fuelId = props.fuelId;
    this.measureType = props.measureType;
    this.dealValue = props.dealValue;

    this.amount = props.measureType === ContractProductMeasureType.gallon ? props.amount : undefined;
    this.dealType = props.measureType === ContractProductMeasureType.inBulk ? props.dealType : undefined;
    this.dealValueType = props.measureType === ContractProductMeasureType.inBulk ? props.dealValueType : undefined;
    this.dealMinValue = props.measureType === ContractProductMeasureType.inBulk ? props.dealMinValue : undefined;
  }
}