import { Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import {
  ArrayField,
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  downloadCSV,
  useGetIdentity,
  useListContext,
  useRecordContext
} from 'react-admin';
import { List, Permissions } from '../../components';
import { PERMISSIONS } from '../../constants';
import { ContractProductMeasureType, ContractType } from '../../models';
import { UserRole } from '../../providers/authProvider';
import { exportOptions, formatNumber, getChainId, getCompanyId } from '../../utils/utils';
import ApproveContractButton from './approveContractButton';
import ReproveContractButton from './reproveContractButton';

const getFilter = () => {
  if (getCompanyId()) return { companyId: getCompanyId() };
  if (getChainId()) return { chainId: getChainId() };
}

const ContractPanel = (props) => {
  const { identity } = useGetIdentity();
  const record = useRecordContext();
  record.predecessorContractIds = [record.predecessorContractId];

  const DealValueField = () => {
    const scopedData = useRecordContext();

    const { dealValue, dealValueType } = scopedData;
    if (dealValueType === 'percentage') return (
      <span>{`${dealValue}%`}</span>
    );

    return (
      <span>{formatNumber(+dealValue, { style: 'currency', currency: 'BRL' })}</span>
    );
  };

  const deletedContract = (record) => ({
    backgroundColor: record.isDeleted ? '#ff9d85' : '#ffffff',
  })

  const ListOldContracts = (props) => {
    const record = useRecordContext(props);
    const {
      data,
      total,
    } = useListContext(props);

    return (
      <Datagrid
        data={data.map(item => ({ ...item, id: `${record.id}-${item.id}` }))}
        ids={(data || []).map(item => item.id)}
        total={total}
        bulkActionButtons={false}
        expand={<ContractPanel showPredecessorContracts={false} />}
        empty={<Typography component="span" variant="body2" align="center">Sem alterações</Typography>}
      >
        <TextField source="name" label="Nome" />
        <TextField source="code" label="Código" />
        <Permissions userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField label="Rede" reference="chains" source="chainId" link={identity?.role === UserRole.admin}>
            <TextField source="name" />
          </ReferenceField>
        </Permissions>
        <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
          <ReferenceField label="Grupo de Transportadora" reference="companies" source="companyId" link={identity?.role === UserRole.admin}>
            <TextField source="name" />
          </ReferenceField>
        </Permissions>
        <DateField source="createdAt" label="Data de criação" showTime />
      </Datagrid>
    );
  }

  const NumberChip = () => {
    return record.billingDay?.map((_, index) => <ChipField source={`billingDay[${index}]`} />)
  }

  return (
    <SimpleShowLayout>
      {
        record.isDeprecated &&
        <BooleanField
          source="isDeprecated"
          label="Descontinuado"
          valueLabelTrue="O contrato não está em vigor"
        />
      }
      <TextField source="code" label="Código" />
      <TextField label="Contrato" source="name" />
      <Permissions userRoles={[UserRole.admin, UserRole.company]}>
        <ReferenceField label="Rede" reference="chains" source="chainId" link={identity?.role === UserRole.admin}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
        <ReferenceField label="Grupo de Transportadora" reference="companies" source="companyId" link={identity?.role === UserRole.admin}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
        <SingleFieldList style={{ marginBottom: 10 }} linkType={identity && identity.role === UserRole.company ? "show" : "edit"}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source='startDate' label='Inicio' showTime emptyText='-' />
      <DateField source='endDate' label='Término' showTime emptyText='-' />
      <BooleanField source="isForAggregated" label="Válido para agregados" />
      <BooleanField source="isPriceLogEnabled" label="Compartilhar histórico de preço" />
      <BooleanField source="isPriceLogForAggregateEnabled" label="Compartilhar histórico de preço com Agregado" />
      {
        (record.status === ContractType.accepted || record.status === ContractType.notApprove) &&
        <ReferenceField source="authorizedByEmployeeId" reference="employees" label={record.status === ContractType.accepted ? 'Aprovado por' : 'Reprovado por'} emptyText="--">
          <TextField source="name" />
        </ReferenceField>
      }
      {
        (record.status === ContractType.accepted || record.status === ContractType.notApprove) &&
        <DateField source="authorizedAt" label={record.status === ContractType.accepted ? 'Aprovado ás' : 'Reprovado ás'} showTime emptyText="--" />
      }
      <ArrayField label="Dias de faturamento" source="billingDay">
        <NumberChip />
      </ArrayField>
      <NumberField source="dueDate" label="Prazo de Vencimento (em dias)" emptyText="Não informado" />
      <SelectField
        label="Forma de Pagamento"
        source="paymentType"
        emptyText="Não informado"
        choices={[
          { id: 'bill', name: 'Boleto' },
          { id: 'deposit', name: 'Depósito' },
        ]}
      />
      <TextField label="Período de promoção (intervalo em dias)" source="dealPeriod" emptyText="Indeterminado" />
      <ArrayField label="Acordos de Preço" source="contractFuels">
        <Datagrid bulkActionButtons={false} style={{ marginBottom: 15 }}>
          <ReferenceField link={null} label="Combustível" reference="fuels" source="fuelId">
            <TextField source="name" />
          </ReferenceField>
          <SelectField
            label="Tipo de acordo"
            source="dealType"
            choices={[{ id: 'discount', name: 'Desconto' }, { id: 'increase', name: 'Acréscimo' }]}
          />
          <DealValueField label="Valor do Acordo" />
          <SelectField
            label="Tipo do Valor"
            source="dealValueType"
            choices={[
              { id: 'cents', name: 'Centavos' },
              { id: 'percentage', name: 'Porcentagem' },
              { id: 'prefixed', name: 'Prefixado' },
            ]}
          />
          <TextField label="Quantidade Mínima(L)" source="dealMinValue" />
          <BooleanField source="isForAggregated" label="Para Agregados" looseValue />
        </Datagrid>
      </ArrayField>
      <ArrayField label="Acordos de Produtos" source="contractProducts" >
        <Datagrid bulkActionButtons={false}>
          <ReferenceField link={null} label="Combustível" reference="fuels" source="fuelId">
            <TextField source="name" />
          </ReferenceField>
          <SelectField source="measureType" label="Medida" choices={ContractProductMeasureType.contractProductMeasureTypeChoices} />
          <FunctionField render={record => record && record.amount ? `${record.amount} litros` : '--'} label="Capacidade" />
          <SelectField
            label="Tipo de Acordo"
            source="dealType"
            choices={[{ id: 'discount', name: 'Desconto' }, { id: 'increase', name: 'Acréscimo' }]}
            emptyText="--"
          />
          <DealValueField label="Valor do Acordo" />
          <SelectField
            label="Tipo do Valor"
            source="dealValueType"
            emptyText="--"
            choices={[
              { id: 'cents', name: 'Centavos' },
              { id: 'percentage', name: 'Porcentagem' },
              { id: 'prefixed', name: 'Prefixado' },
            ]}
          />
          <TextField label="Quantidade Mínima(L)" source="dealMinValue" emptyText="--" />
        </Datagrid>
      </ArrayField>

      {
        ((record.status === ContractType.pending || record.status === ContractType.preApproved) && record.predecessorContractId) &&
        <Labeled label="Contrato antigo" style={{ marginTop: 20 }} width>
          <ReferenceArrayField
            reference="contracts"
            source="predecessorContractIds"
          >
            <ListOldContracts />
          </ReferenceArrayField>
        </Labeled>
      }

      {
        (props.showPredecessorContracts !== false && record.status !== ContractType.pending && record.status !== ContractType.preApproved) &&
        <Labeled label="Histórico de atualizações" style={{ marginTop: 20 }} width>
          <ReferenceManyField
            reference="contracts"
            target="sucessorContractId"
            filter={{ showDeleted: true }}
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid
              bulkActionButtons={false}
              expand={<ContractPanel showPredecessorContracts={false} />}
              empty={<Typography component="span" variant="body2" align="center">Sem alterações</Typography>}
              rowStyle={deletedContract}
            >
              <TextField source="name" label="Nome" />
              <TextField source="code" label="Código" />
              <Permissions userRoles={[UserRole.admin, UserRole.company]}>
                <ReferenceField label="Rede" reference="chains" source="chainId" link={identity?.role === UserRole.admin}>
                  <TextField source="name" />
                </ReferenceField>
              </Permissions>
              <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
                <ReferenceField label="Grupo de Transportadora" reference="companies" source="companyId" link={identity?.role === UserRole.admin}>
                  <TextField source="name" />
                </ReferenceField>
              </Permissions>
              <DateField source="createdAt" label="Data de criação" showTime />
              <FunctionField render={record => ContractType.transalateType(record.status)} label="Status" />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
      }
    </SimpleShowLayout>
  );
};

const ContractsFilter = props => {
  const { identity } = useGetIdentity();
  return (
    <Filter {...props}>
      <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
        <ReferenceInput
          source="companyId"
          reference="companies"
          filter={identity?.role === UserRole.admin ? null : { chainId: getChainId() }}
          alwaysOn
        >
          <SelectInput style={{ minWidth: 250 }} label="Grupo de Transportadora" resettable={true} optionText="name" sx={{ width: 170 }} />
        </ReferenceInput>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]}>
        <ReferenceInput
          source="chainId"
          reference="chains"
          filter={identity?.role === UserRole.admin ? null : { companyId: getCompanyId() }}
          alwaysOn
        >
          <SelectInput label="Redes" resettable={true} optionText="name" />
        </ReferenceInput>
      </Permissions>
      <SelectInput
        label="Status"
        source="status"
        resettable={true}
        emptyText="Todos"
        choices={[{ id: 'accepted', name: 'Aceito' }, { id: 'pending', name: 'Pendente' }]}
        alwaysOn
      />
      {identity && [UserRole.admin, UserRole.company].includes(identity.role) &&
        <ReferenceArrayInput
          source="fuelIds"
          reference="fuels"
          sort={{ field: "name", order: "ASC" }}
          perPage={false}
          alwaysOn
        >
          <SelectArrayInput label="Combustíveis" sx={{ marginBottom: 0.5, paddingTop: 1 }} optionText="name" />
        </ReferenceArrayInput>
      }
      {identity && [UserRole.admin, UserRole.company].includes(identity.role) &&
        <ReferenceArrayInput
          source="stationIds"
          reference="stations"
          sort={{ field: "name", order: "ASC" }}
          perPage={false}
          filter={identity?.role === UserRole.admin ? null : { companyId: getCompanyId() }}
          alwaysOn
        >
          <SelectArrayInput label="Postos" sx={{ marginBottom: 0.5, paddingTop: 1 }} optionText="name" />
        </ReferenceArrayInput>
      }
    </Filter>
  );
};

export const ContractList = ({ ...props }) => {
  const { identity } = useGetIdentity();
  const headerCellStyle = { '& .RaDatagrid-headerCell': { backgroundColor: '#E5E5E5' } };

  const ContractActions = ({ basePath }) => (
    <TopToolbar>
      <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
        <CreateButton label="CRIAR" basePath={basePath} />
      </Permissions>
      <ExportButton exporter={contractsExporter} />
    </TopToolbar>
  );

  const contractsExporter = async (data, fetchRelatedRecords) => {
    let contracts = data;

    const fetchCompany = fetchRelatedRecords(contracts, 'companyId', 'companies');
    const fetchChain = fetchRelatedRecords(contracts, 'chainId', 'chains');
    const fetchData = await Promise.all([fetchCompany, fetchChain]);

    contracts = contracts.map(contract => {
      const { id, name, companyId, chainId, dealPeriod, status, createdAt, startDate, endDate } = contract;
      return {
        id,
        nome: name,
        grupoDeTransportadora: companyId ? fetchData[0][companyId].name : 'Não identificado',
        rede: chainId ? fetchData[1][chainId].name : 'Não identificado',
        intervalo: dealPeriod,
        inicio: startDate ? moment(startDate).format('DD/MM/YYYY HH:mm:ss') : 'Sem Data',
        termino: endDate ? moment(endDate).format('DD/MM/YYYY HH:mm:ss') : 'Sem Data',
        status,
        data: moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
      }
    });

    jsonExport(contracts, exportOptions, (err, csv) => {
      downloadCSV(csv, 'Contratos');
    });
  }

  const ContractButton = () => {
    const { identity } = useGetIdentity();
    const record = useRecordContext();

    return (
      <>
        {
          ([ContractType.accepted, ContractType.pending, ContractType.preApproved].includes(record.status) && record.isDeprecated === false) &&
          <Permissions userRoles={[UserRole.chain]}>
            <ReferenceField
              source="id"
              label={false}
              link={false}
              reference="predecessor-contract"
            >
              <EditButton resource="contracts" />
            </ReferenceField>
          </Permissions>
        }
        {
          ([ContractType.pending, ContractType.preApproved].includes(record.status)) &&
          <>
            <Permissions permissions={[PERMISSIONS.APPROVE_CONTRACTS]}>
              <ApproveContractButton />
            </Permissions>
            <Permissions permissions={[PERMISSIONS.APPROVE_CONTRACTS]}>
              <ReproveContractButton />
            </Permissions>
          </>
        }
      </>
    );
  }

  return (
    <List
      {...props}
      title="Contratos"
      filter={getFilter()}
      filters={<ContractsFilter />}
      actions={<ContractActions />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid sx={headerCellStyle} expand={<ContractPanel bulkActionButtons={false} />} bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <TextField source="code" label="Código" textAlign="center" />
        <Permissions userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField reference="chains" source="chainId" link={identity?.role === UserRole.admin} textAlign="center">
            <TextField label="Rede" source="name" />
          </ReferenceField>
        </Permissions>
        <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
          <ReferenceField reference="companies" source="companyId" link={identity?.role === UserRole.admin} textAlign="center">
            <TextField label="Grupo de Transportadora" source="name" />
          </ReferenceField>
        </Permissions>
        <DateField source='createdAt' label='Data de criação' showTime textAlign="center" />
        <DateField source='endDate' label='Vencimento' textAlign="center" />
        <FunctionField label="Status" render={record => ContractType.transalateType(record.status)} textAlign="center" />
        <ContractButton />
      </Datagrid>
    </List>);
};

export default ContractList;