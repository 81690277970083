import React from "react";
import { Datagrid, DateField, DateInput, downloadCSV, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, useRecordContext } from 'react-admin';
import { ReportList, ListPagination, Permission, CircleStatus, ColoredDiferenceValueField, CustomizableDatagrid, List } from '../../components';
import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { VehicleAverageChart } from "../../components";
import jsonExport from 'jsonexport/dist';
import { exportOptions } from '../../utils/utils';
import { UserRole } from "../../providers/authProvider";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const AveragePanel = () => {
  const { filterValues } = useListContext();
  const record = useRecordContext();

  return (
    <List
      resource="vehicles/averages"
      title="Média"
      filter={{
        vehicleId: record.vehicleId,
        driverId: record.driverId,
        from: filterValues.from,
        to: filterValues.to,
      }}
      pagination={false}
      exporter={false}
    >
      <VehicleAverageChart />
    </List>
  );
}

const AverageList = () => {
  const classes = useStyles();
  const props = useListContext();

  return (
    <>
      <Typography className={classes.title}>Média dos veículos</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: { backgroundColor: '#E5E5E5' } }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['manager_id', 'vehicle_group_id', 'vehicle_base_id', 'driver_id', 'expectedAverage', 'license_plate', 'model', 'amount', 'average', 'averageEfficiency', 'diffValue']}
        expand={<AveragePanel />}
        bulkActionButtons={false}
        isRowExpandable={row => row.average}
      >
        <ReferenceField
          label="Gestor"
          emptyText="Gestor não identificado"
          source="manager_id"
          basePath="managers"
          reference="managers"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Filial"
          emptyText="Filial não identificada"
          source="vehicle_base_id"
          basePath="vehicle-bases"
          reference="vehicle-bases"
          textAlign="center"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Grupo"
          emptyText="Grupo não identificada"
          source="vehicle_group_id"
          basePath="vehicle-groups"
          reference="vehicle-groups"
          textAlign="center"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Motorista"
          emptyText="Não identificado"
          source="driver_id"
          basePath="drivers"
          reference="drivers"
          textAlign="center"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="model" label="Modelo" textAlign="center" />
        <TextField source="license_plate" label="Placa" textAlign="center" />
        <NumberField
          source="amount"
          label="Volume Gasto (litros)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="expected_average"
          label="Média Ideal (Km/l)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="average"
          label="Média Real (Km/l)"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <FunctionField
          label="Eficiência (%)"
          sortBy="averageEfficiency"
          textAlign="center"
          render={record => (<CircleStatus value={record.averageEfficiency} emptyText="--" />)}
        />
        <ColoredDiferenceValueField
          source="diffValue"
          label="Diferença (R$)"
          textAlign="center"
          emptyText="--"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
      </CustomizableDatagrid>
    </>
  );
};

const handleExporter = async (data, fetchRelatedRecords) => {

  const fetchGroup = fetchRelatedRecords(data, 'vehicle_group_id', 'vehicle-groups');
  const fetchBase = fetchRelatedRecords(data, 'vehicle_base_id', 'vehicle-bases');
  const fetchManager = fetchRelatedRecords(data, 'manager_id', 'managers');
  const fetchDriver = fetchRelatedRecords(data, 'driver_id', 'drivers');
  const fetchData = await Promise.all([fetchManager, fetchGroup, fetchBase, fetchDriver]);

  data = data.map(item => {
    const { id, manager_id, vehicle_group_id, vehicle_base_id, driver_id } = item;
    return {
      id,
      gestor: manager_id ? fetchData[0][manager_id].name : 'Não identificado',
      grupo: vehicle_group_id ? fetchData[1][vehicle_group_id].name : 'Não identificado',
      base: vehicle_base_id ? fetchData[2][vehicle_base_id].name : 'Não identificado',
      motorista: driver_id ? fetchData[3][driver_id].name : 'Não identificado',
      placa: item.license_plate,
      modelo: item.model,
      volumeGasto: item.amount,
      mediaEsperada: item.expected_average,
      mediaReal: item.average,
      eficiencia: item.averageEfficiency,
      diferencaEmDinheiro: item.diffValue,
    }
  });

  jsonExport(data, exportOptions, (err, csv) => {
    downloadCSV(csv, 'Medias');
  });
}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField
        label="Gestor"
        source="managerId"
        reference="managers"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <TextField
          source={'name'}
        />
      </ReferenceField>,
      <ReferenceField
        label="Filial"
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <TextField
          source={'name'}
        />
      </ReferenceField>,
      <ReferenceField
        label="Grupo"
        source="vehicleGroupId"
        reference="vehicle-groups"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <TextField
          source={'name'}
        />
      </ReferenceField>,
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const AveragesReport = (props) => {
  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn />,
    <ReferenceInput
      source="managerId"
      reference="managers"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        label="Gestor"
        optionText={'name'}
      />
    </ReferenceInput>,
    <ReferenceInput
      source="vehicleBaseId"
      reference="vehicle-bases"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput
        label="Filial"
        optionText={'name'}
        emptyText="Todos"
      />
    </ReferenceInput>,
    <ReferenceInput
      source="vehicleGroupId"
      reference="vehicle-groups"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        label="Grupo"
        optionText={'name'}
      />
    </ReferenceInput>,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          label="Grupo de Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
  ];

  return (
    <ReportList
      {...props}
      title="Médias"
      basePath="vehicles"
      resource="reports/averages"
      filters={listFilters}
      sort={{ field: 'averageEfficiency', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().startOf('day').subtract(1, 'month').toISOString(),
        to: moment().endOf('day').toISOString(),
        companyId: localStorage.getItem('companyId'),
      }}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={handleExporter}
      titleOnPrint="Relatório de médias"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <AverageList />
    </ReportList>)
};

export default AveragesReport;