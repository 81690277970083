import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useGetIdentity, useListContext } from 'react-admin';
import { CustomizableDatagrid, LazyLoadAutoCompleteInput, ListPagination, Permission, Permissions, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { downloadCSV, formatCpf, formatNumber, getChainId, getCompanyId } from '../../utils/utils';
import { FillinReviewStatus } from '../../models';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const FillinList = () => {
  const classes = useStyles();
  const props = useListContext();

  return (
    <>
      <Typography className={classes.title}>Abastecimentos</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        defaultColumns={['stationId', 'contractId', 'value', 'amount', 'pricePerLiter', 'oldPricePerLiter', 'fuelId', 'receivedAt', 'numericId', 'invoiceId', 'billId', 'status']}
        bulkActionButtons={false}
      >
        <NumberField source="numericId" label="Código Transação" />
        <ReferenceField
          label="Código Cliente"
          emptyText="Não identificado"
          source="subCompanyId"
          reference="sub-companies"
        >
          <TextField source="code" />
        </ReferenceField>
        <ReferenceField label="Filial" source="vehicleId" reference="vehicles">
          <ReferenceField label="Filial" source="vehicleBaseId" reference="vehicle-bases">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <DateField source="receivedAt" label="Data" showTime locales="pt-BR" />
        <ReferenceField label="Placa" source="vehicleId" emptyText="Não identificado" reference="vehicles" link="show">
          <TextField source="licensePlate" />
        </ReferenceField>
        <ReferenceField
          label="Tipo Frota"
          emptyText="Não identificado"
          source="driverId"
          reference="drivers"
          link={false}
        >
          <FunctionField render={record => record.isAggregated ? "Agregado" : "Própria"} />
        </ReferenceField>
        <ReferenceField label="Modelo Veículo" source="vehicleId" emptyText="Não identificado" reference="vehicles" link="show">
          <TextField source="model" />
        </ReferenceField>
        <TextField source="empty" label="Número Frota" />
        <ReferenceField label="Ano" source="vehicleId" emptyText="Não identificado" reference="vehicles" link="show">
          <TextField source="year" />
        </ReferenceField>
        <ReferenceField
          label="Matrícula"
          emptyText="Não identificado"
          source="driverId"
          reference="drivers"
          link={false}
        >
          <TextField source="register" />
        </ReferenceField>
        <ReferenceField
          label="Nome Motorista"
          emptyText="Não identificado"
          source="driverId"
          reference="drivers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="empty" label="Serviço" emptyText="Abastecimento" />
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuelId"
          basePath="fuels"
          reference="fuels"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="contractId" reference="contracts" label="Contrato" textAlign="center" emptyText="--" link={false}>
          <TextField source="code" />
        </ReferenceField>
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="pricePerLiter"
          label="Preço por litro - Acordo"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <TextField label="Odômetro" source="odometer" />
        <FunctionField label="Km rodados" render={record => record.lastOdometer ? record.odometer - record.lastOdometer : '-'} />
        <FunctionField label="Km por Litro" render={record => (record.lastOdometer && record.odometer - record.lastOdometer) !== 0 ? formatNumber((record.odometer - record.lastOdometer) / record.amount, { maximumFractionDigits: 2 }) : 0} />
        <NumberField
          source="value"
          label="Valor Emissão"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <ReferenceField
          label="Código Estabelecimento"
          source="stationId"
          reference="stations"
        >
          <TextField source="code" />
        </ReferenceField>
        <ReferenceField
          label="Nome Estabelecimento"
          source="stationId"
          reference="stations"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="empty" label="Tipo Estabelecimento" emptyText="Posto de Combustível" />
        <ReferenceField
          label="Endereço"
          source="stationId"
          reference="stations"
        >
          <FunctionField render={record => record.street + (record.number || '') + (record.complement || '')} />
        </ReferenceField>
        <ReferenceField
          label="Bairro"
          source="stationId"
          reference="stations"
        >
          <TextField source="neighborhood" />
        </ReferenceField>
        <ReferenceField
          label="Cidade"
          source="stationId"
          reference="stations"
        >
          <TextField source="city" />
        </ReferenceField>
        <ReferenceField
          label="UF"
          source="stationId"
          reference="stations"
        >
          <TextField source="state" />
        </ReferenceField>
        <ReferenceField label="Família Veículo" source="vehicleId" reference="vehicles">
          <TextField source="family" />
        </ReferenceField>
        <ReferenceField label="Grupo" source="vehicleId" reference="vehicles">
          <ReferenceField label="Grupo" source="vehicleGroupId" reference="vehicle-groups">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField label="Gestor Responsável" source="vehicleId" reference="vehicles">
          <ReferenceField label="Gestor Responsável" source="managerId" reference="managers">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permissions userRoles={[UserRole.admin, UserRole.chain]} label="Posto">
        <ReferenceField emptyText="Todos" source="stationId" textAlign="center" reference="stations" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motorista">
        <ReferenceField emptyText="Todos" source="driverId" textAlign="center" reference="drivers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="numericId" label="Identificador" />
      <FunctionField source="hasInvoice" label="Fatura" render={record => record.hasInvoice === true ? 'Com Fatura' : record.hasInvoice === false ? 'Sem Fatura' : 'Todos'} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const DetailedFillinsReport = (props) => {
  const { identity } = useGetIdentity();

  const filterValue = { stationReviewStatus: FillinReviewStatus.approved, ...(identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}) };

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.chain]} alwaysOn>
      <ReferenceInput
        label="Posto"
        source="stationId"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
      >
        <SelectInput
          emptyText="Todos"
          label="Posto"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permissions>,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
        filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
      >
        <SelectInput
          optionText={'name'}
          label="Filial"
          emptyText="Todos"
        />
      </ReferenceInput>
    </Permissions>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <FormDataConsumer>
        {({ formData }) => (
          <LazyLoadAutoCompleteInput
            label="Motorista"
            reference="drivers"
            source="driverId"
            format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
            style={{ minWidth: 160, marginBottom: 4 }}
            record={formData}
            size={"small"}
          />
        )}
      </FormDataConsumer>
    </Permissions>,
    <ReferenceInput label="Produto" source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
      <SelectInput
        emptyText="Todos"
        label="Produto"
        optionText={'name'}
      />
    </ReferenceInput>,
    <SelectInput
      source="hasInvoice"
      label="Fatura"
      choices={[
        { name: "Sem nota fiscal", value: false },
        { name: "Com nota fiscal", value: true },
      ]}
      emptyText="Todos"
      optionText="name"
      optionValue="value"
      allowEmpty
      alwaysOn
    />,
  ];

  const fillinsExporter = async (data, fetchRelatedRecords) => {
    let fillinInfo = data;

    const fetchPromises = [
      fetchRelatedRecords(fillinInfo, 'fuelId', 'fuels'),
      fetchRelatedRecords(fillinInfo, 'stationId', 'stations'),
      fetchRelatedRecords(fillinInfo, 'driverId', 'drivers'),
      fetchRelatedRecords(fillinInfo, 'subCompanyId', 'sub-companies'),
      fetchRelatedRecords(fillinInfo, 'vehicleId', 'vehicles'),
      // fetchRelatedRecords(fillinInfo.map(f => f.vehicle).filter(f => f), 'vehicleBaseId', 'vehicle-bases'),
      // fetchRelatedRecords(fillinInfo.map(f => f.vehicle).filter(f => f), 'vehicleGroupId', 'vehicle-groups'),
      // fetchRelatedRecords(fillinInfo.map(f => f.vehicle).filter(f => f), 'managerId', 'managers'),
    ];

    const fetchData = await Promise.all(fetchPromises);

    fillinInfo = fillinInfo.map(fillin => {
      const { fuelId, stationId, driverId, subCompanyId, valetId, attendantId, vehicleId, vehicle, ...fillinRest } = fillin;
      return {
        "CODIGO TRANSACAO": fillinRest.numericId,
        "CODIGO CLIENTE": subCompanyId ? fetchData[3][subCompanyId].code : 'Não identificado',
        "NOME REDUZIDO": vehicle && vehicle.vehicleBaseId && fetchData[5][vehicle.vehicleBaseId] ? fetchData[5][vehicle.vehicleBaseId].name : 'Não identificado',
        "DATA TRANSACAO": moment(fillinRest.receivedAt).format('DD/MM/YYYY HH:mm:ss'),
        "PLACA": vehicleId && fetchData[4][vehicleId] ? fetchData[4][vehicleId].licensePlate : 'Não identificado',
        "TIPO FROTA": driverId && (fetchData[2][driverId].isAggregated ? 'AGREGADO' : 'PROPRIA'),
        "MODELO VEICULO": vehicleId && fetchData[4][vehicleId] ? fetchData[4][vehicleId].model : '',
        "NUMERO FROTA": '',
        "ANO": vehicleId && fetchData[4][vehicleId] ? fetchData[4][vehicleId].year : '',
        "MATRICULA": driverId ? fetchData[2][driverId].register : '',
        "NOME MOTORISTA": driverId ? fetchData[2][driverId].name : '',
        "SERVICO": 'Abastecimento',
        "TIPO COMBUSTIVEL": fuelId ? fetchData[0][fuelId].name : 'Não identificado',
        "LITROS": formatNumber(fillinRest.amount, { minimumFractionDigits: 2 }),
        "VL/LITRO": `R$ ${formatNumber(fillinRest.pricePerLiter, { minimumFractionDigits: 2 })}`,
        "HODOMETRO OU HORIMETRO": fillinRest.odometer,
        "KM RODADOS OU HORAS TRABALHADAS": fillinRest.odometer - fillinRest.lastOdometer,
        "KM/LITRO OU LITROS/HORA": (fillinRest.odometer - fillinRest.lastOdometer) !== 0 ? formatNumber((fillinRest.odometer - fillinRest.lastOdometer) / fillinRest.amount, { maximumFractionDigits: 2 }) : '',
        "VALOR EMISSAO": `R$ ${formatNumber(fillinRest.value, { minimumFractionDigits: 2 })}`,
        "CODIGO ESTABELECIMENTO": stationId ? fetchData[1][stationId].code : '',
        "NOME ESTABELECIMENTO": stationId ? fetchData[1][stationId].name : 'Não identificado',
        "TIPO ESTABELECIMENTO": 'Posto de combustivel',
        "ENDERECO": stationId ? `${fetchData[1][stationId].street} ${fetchData[1][stationId].streetNumber || ''} ${fetchData[1][stationId].complement || ''}` : '',
        "BAIRRO": stationId ? fetchData[1][stationId].neighbourhood : '',
        "CIDADE": stationId ? fetchData[1][stationId].city : '',
        "UF": stationId ? fetchData[1][stationId].state : '',
        "INFORMACAO ADICIONAL 1": '',
        "INFORMACAO ADICIONAL 2": '',
        "INFORMACAO ADICIONAL 3": '',
        "INFORMACAO ADICIONAL 4": '',
        "INFORMACAO ADICIONAL 5": '',
        "FORMA TRANSACAO": '',
        "CODIGO LIBERACAO RESTRICAO": '',
        "SERIE POS": '',
        "NUMERO CARTAO": '',
        "FAMILIA VEICULO": vehicleId && fetchData[4][vehicleId] ? fetchData[4][vehicleId].family : '',
        "GRUPO RESTRICAO": vehicle && vehicle.vehicleGroupId && fetchData[6][vehicle.vehicleGroupId] ? fetchData[6][vehicle.vehicleGroupId].name : '',
        "CODIGO EMISSORA": '',
        "RESPONSAVEL": vehicle && vehicle.managerId && fetchData[7][vehicle.managerId] ? fetchData[7][vehicle.managerId].name : '',
      };
    });

    jsonExport(fillinInfo, { rowDelimiter: ';' }, (err, csv) => {
      downloadCSV(csv, 'abastecimentos_detalhados');
    });
  }

  return (
    <ReportList
      {...props}
      basePath="fillins"
      title="Abastecimentos"
      resource="fillins"
      filters={listFilters}
      sort={{ field: 'receivedAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().subtract(1, 'month').startOf('day').toISOString(),
        to: moment().endOf('day').toISOString(),
      }}
      filter={filterValue}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={fillinsExporter}
      titleOnPrint="Relatório de Abastecimentos Detalhado"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <FillinList />
    </ReportList>
  );
};

export default DetailedFillinsReport;