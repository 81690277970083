import { useEffect, useState } from 'react';
import {
    BooleanInput,
    Edit,
    NumberInput,
    SimpleForm,
    minValue,
} from 'react-admin';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';

const CompanyConfig = (props) => {

    return (
        <Permission permission={PERMISSIONS.COMPANY_CONFIG}>
            <Edit
                id={getCompanyId()}
                title="Geral"
                resource="company-config"
                mutationMode="pessimistic"
            >
                <SimpleForm>
                    <BooleanInput label="Obrigar veículos a informar odômetro" source={"vehicleOdometer"} />
                    <BooleanInput label="Obrigar motoristas a informar odômetro" source={"driverOdometer"} />
                    <BooleanInput label="Permitir Abastecimentos de veículos sem operação vinculada" source="isFillinWithoutOperation" />
                    <NumberInput
                        sx={{ width: 435 }}
                        label="Intervalo obrigatório entre abastecimentos (em horas)"
                        source={"intervalBetweenFillin"}
                        validate={minValue(0)}
                    />
                </SimpleForm>
            </Edit>
        </Permission>
    );
};

export default CompanyConfig;