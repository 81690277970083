import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import copyClipBoard from 'copy-to-clipboard';

const LOOSE_STRIP_REGEX = /[^\d]/g;

export const strip = (str) => {
    var regex = LOOSE_STRIP_REGEX;
    return (str || "").toString().replace(regex, "");
}

export const formatCellphone = (num) => {
  if (!num) return num;
  if (num.length < 3)
    return `${num.substring(0, 2)}`;
  if (num.length < 7)
    return `(${num.substring(0, 2)}) ${num.substring(2, 6)}`;
  if (num.length < 11)
    return `(${num.substring(0, 2)}) ${num.substring(2, 6)}-${num.substring(6, 10)}`;
  return `(${num.substring(0, 2)}) ${num.substring(2, 7)}-${num.substring(7, 11)}`;
}

export const formatPrice = (number, options) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', ...options }).format(number);
}

export const formatCep = (num) => {
  if (!num) return num;
  if (num.length < 6)
    return `${num.substring(0, 5)}`;
  return `${num.substring(0, 5)}-${num.substring(5, 8)}`;
}

export const formatCpf = (num) => {
  if (!num) return num;
  if (num.length < 4)
    return `${num.substring(0, 3)}`;
  if (num.length < 7)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}`;
  if (num.length < 10)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}`;
  return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}-${num.substring(9, 11)}`
}

export const formatCnpj = (num) => {
  if (!num) return num;
  if (num.length < 3)
    return `${num.substring(0, 2)}`;
  if (num.length < 6)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}`;
  if (num.length < 9)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}`;
  if (num.length < 13)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}`;
  return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}-${num.substring(12, 14)}`
}

export const adressFormat = record =>
  `${record.street || ''}, ${record.streetNumber || ''} - ${record.city || ''}/${record.state || ''}`

export const formatId = (num) => {
  var id = '0000' + num;
  return `${id.substring(id.length - 4)} `;
}

export const formatNumber = (number, options) => {
  const { unit, style, currency, minimumFractionDigits, maximumFractionDigits, minimumSignificantDigits, minimumIntegerDigits } = options || {};
  return new Intl.NumberFormat('pt-BR', { unit, style: style || "decimal", currency, minimumFractionDigits, maximumFractionDigits, minimumSignificantDigits, minimumIntegerDigits }).format(number);
}

export const getUserId = () => {
  const userId = localStorage.getItem('id');
  return userId;
}

export const getChainId = () => {
  const chainId = localStorage.getItem('chainId');
  return chainId;
}

export const getStationId = () => {
  const stationId = localStorage.getItem('stationId');
  return stationId;
}

export const getCompanyId = () => {
  const companyId = localStorage.getItem('companyId');
  return companyId;
}

// needed to overwrite DateInput formatter
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const convertDateToString = (value) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return null;
  const pad = '00';
  const yyyy = value.getUTCFullYear().toString();
  const MM = (value.getUTCMonth() + 1).toString();
  const dd = value.getUTCDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateInputFormatter = (value) => {
  if (value == null || value === '') {
    return '';
  }
  if (value instanceof Date) {
    return convertDateToString(value);
  }
  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value;
  }
  return convertDateToString(new Date(value));
};

export const exportOptions = {
  rowDelimiter: ';',
  typeHandlers: {
    Number: (value) => formatNumber(value),
  },
};

export const copyToClipboard = (dataCopy) => {
  copyClipBoard(dataCopy);
};

export const downloadCSV = (csv, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.csv`);
    fakeLink.click();
  }
};

export const transformFile = file => {
  if (!(file instanceof File)) {
    return file;
  }

  const preview = URL.createObjectURL(file);
  const transformedFile = {
    rawFile: file,
    src: preview,
    title: file.name,
  };

  return transformedFile;
};

export const addMinutesToDate = (date, minutes) => new Date(date.getTime() + minutes * 60000);

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToXLSX = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export const isDifferenceSignificant = (value1, value2) => {
  const difference = value1 - value2;
  const roundedDifference = parseFloat(difference.toFixed(2));
  return Math.abs(roundedDifference) >= 0.01;
}

export const linkDriveType = (record, reference) => {
  const type = record.isAggregated ? 'aggregated-drivers' : record.isValet ? 'valets' : reference;
  return `/${type}/${record.id}/show`;
}