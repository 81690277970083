import { PERMISSIONS } from '../../constants';
import ComponentWithPermissions from '../ComponentWithPermissions';
import create from './FillinInvoiceImport';
import list from './BillList';
import show from './BillShow';

export default {
  create: new ComponentWithPermissions({ component: create, permissions: [PERMISSIONS.CREATE_INVOICE] }),
  list,
  show,
  name: 'bills',
  permissions: [],
}