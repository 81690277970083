import LockOpen from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { Button, Datagrid, DateField, FunctionField, ReferenceField, ReferenceInput, SelectInput, TextField, useDataProvider, useGetIdentity, useListContext, useNotify, usePermissions, useRecordContext, useRefresh } from 'react-admin';
import { ListPagination, Permission, Permissions, ReportList, UploadInvoiceBulkAction } from '../../components';
import { PERMISSIONS } from '../../constants';
import { UserRole } from '../../providers/authProvider';
import { formatCpf, getChainId, getCompanyId } from "../../utils/utils";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30,
    paddingBottom: 30,
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const UnBlockedButton = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleUnBlocked = () => {
    dataProvider.update('drivers/unblock', {
      id: record.id,
      previousData: record
    }).then(() => {
      notify('Motorista foi desbloqueado com sucesso');
      refresh();
    })
      .catch((e) => {
        notify(e.message || 'Não foi possível desbloquear motorista', { type: 'warning' });
      });
  };

  return (
    <Button label={props.label} startIcon={<LockOpen />} onClick={handleUnBlocked} />
  );
}

const BlockedDriverList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  return (
    <>
      <Typography className={classes.title}>Motoristas Bloqueados</Typography>
      <Datagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={false}
        bulkActionButtons={identity?.role === UserRole.chain ? <UploadInvoiceBulkAction /> : false}
      >
        <TextField source="name" label="Nome" />
        <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="blockedAt" label="Bloqueado em" showTime />
        <UnBlockedButton label="Desbloquear" />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <Permissions permissions={[PERMISSIONS.ADMIN]} label="Grupo de Transportadora">
        <ReferenceField emptyText="Todos" source="companyId" textAlign="center" reference="companies" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceField label="Transportadora" emptyText="Todos" source="subCompanyId" textAlign="center" reference="sub-companies" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const BlockedDriversReport = (props) => {
  const { identity } = useGetIdentity();

  const listFilters = [
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Grupo de Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <ReferenceInput
      source="subCompanyId"
      reference="sub-companies"
      filter={{ companyId: localStorage.getItem('companyId') }}
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        emptyText="Todos"
        label="Transportadora"
        optionText={'name'}
      />
    </ReferenceInput>,
  ];

  return (
    <ReportList
      {...props}
      basePath="drivers"
      title="Motoristas Bloqueados"
      resource="drivers"
      filters={listFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      filter={{ isBlocked: true, ...(identity?.role === UserRole.chain ? { chainId: getChainId() } : identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}) }}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={false}
      titleOnPrint="Relatório de Motoristas Bloqueados"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <BlockedDriverList />
    </ReportList>
  );
};

export default BlockedDriversReport;