import React, { Children, useCallback, useEffect, useState } from "react";
import { BooleanField, Datagrid, DatagridHeaderCell, DateField, DateInput, Filter, FormDataConsumer, FunctionField, NumberField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectField, SelectInput, TextField, useGetList, useListContext, useNotify, useRecordContext, useResourceContext } from "react-admin";
import { formatCnpj, getChainId, isDifferenceSignificant } from "../../utils/utils";
import { List, Permissions, UploadInvoiceBulkAction } from "../../components";
import moment from "moment";
import { Checkbox, Chip, Stack, TableCell, TableRow, TableHead, Box, Typography } from "@mui/material";
import { formatPrice, formatNumber } from "../../utils/utils";
import { UserRole } from "../../providers/authProvider";
import { uniq } from "lodash";
import { FillinReviewStatus } from "../../models";

const CheckboxRow = ({ sortCheckedItems, selectedIds, invoiceFillins, currentInvoice }) => {
  const record = useRecordContext();
  const invoiceFillin = (invoiceFillins || []).find(invoice => invoice.fillinIds.includes(record.id));

  const onChangeHandler = (e) => {
    e.stopPropagation();

    sortCheckedItems(e.target.checked, record);
  };

  const activeCheckBox = useCallback(() => {
    return !!(selectedIds.includes(record.id) || invoiceFillin);
  }, [selectedIds, invoiceFillins]);

  return (
    <Checkbox
      checked={activeCheckBox()}
      onChange={onChangeHandler}
      onClick={(e) => {
        e.stopPropagation();
      }}
      disabled={invoiceFillin}
    />
  )
};

const TopListActions = ({ totalAmount, totalValue, totalFuelValues, fuels }) => {

  const getFuelName = (fuelId) => {
    if (!fuels) { return ''; }
    return fuels.find(fuel => fuel.id === fuelId)?.name;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
      style={{ minHeight: 74, paddingBottom: 4 }}
    >
      <Stack direction="row" spacing={1}>
        <Chip label={`Valor total: ${formatPrice(totalValue)}`} color="primary" variant="outlined" />
        <Chip label={`Quantidade total: ${formatNumber(totalAmount)}L`} color="primary" variant="outlined" />
      </Stack>
      <Stack direction="row" spacing={1}>
        {
          totalFuelValues.map((totalFuelValue, index) => (
            <Chip key={index} label={`${getFuelName(totalFuelValue.fuelId)}: ${formatPrice(totalFuelValue.value)}`} color="primary" variant="outlined" />
          ))
        }
      </Stack>
    </Stack>
  )
};

const DatagridHeader = ({ children, selectedIds, handleSelectAll, invoiceFillins, ...props }) => {
  const resource = useResourceContext(props);
  const { sort, setSort } = useListContext(props);

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        sort.field === newField
          ? sort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order;

      setSort({ field: newField, order: newOrder });
    },
    [sort.field, sort.order, setSort]
  );

  const updateSort = setSort ? updateSortCallback : null;

  const activeCheckBoxHeader = useCallback(() => {
    const fillinIds = uniq(selectedIds.concat(invoiceFillins.map(invoice => invoice.fillinIds).flat()));
    if (fillinIds.length === 0) { return false; }

    return !props.data.some(fillin => !fillinIds.includes(fillin.id));
  }, [props.data, selectedIds, invoiceFillins]);

  return (
    <TableHead>
      <TableRow>
        {Children.map(children, (field, index) => {
          if (field.props.label === 'check') {
            return (
              <TableCell key={field.props.label}>
                <Checkbox
                  checked={activeCheckBoxHeader()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectAll(e.target.checked, props.data);
                  }}
                />
              </TableCell>
            );
          }
          return (
            <DatagridHeaderCell
              sort={sort}
              field={field}
              isSorting={
                sort.field ===
                (field.props.sortBy ||
                  field.props.source)
              }
              key={field.props.source || index}
              resource={resource}
              updateSort={updateSort}
            />
          );
        })}
      </TableRow>
    </TableHead>
  )
};

const FillinDetailsDatagrid = (props) => (
  <Datagrid
    rowClick={false}
    {...props}
    bulkActionButtons={false}
    header={<DatagridHeader handleSelectAll={props.handleSelectAll} selectedIds={props.selectedIds} invoiceFillins={props.invoiceFillins} />}
  >
    <CheckboxRow
      label="check"
      sortCheckedItems={props.sortCheckedItems}
      selectedIds={props.selectedIds}
      invoiceFillins={props.invoiceFillins}
      currentInvoice={props.currentInvoice}
    />
    <ReferenceField
      label="Posto"
      reference="stations"
      source="stationId"
      textAlign="center"
      link={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Combustível"
      reference="fuels"
      source="fuelId"
      textAlign="center"
      link={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Veículo"
      reference="vehicles"
      source="vehicleId"
      link={false}
    >
      <TextField source="licensePlate" />
    </ReferenceField>
    <NumberField source="hoseNumber" label="Bico" textAlign="center" emptyText='Não Informado' />
    <NumberField source="amount" label="Quantidade em litros" locales="pt-BR" textAlign="center" />
    <NumberField source="value" label="Valor pago" options={{ style: 'currency', currency: 'BRL' }} textAlign="center" />
    <DateField source="receivedAt" label="Data inclusão" showTime locales="pt-BR" textAlign="center" />
    <BooleanField source="invoiceId" label="Nota Fiscal" looseValue />
    <FunctionField
      source="deliveryId"
      label="Identificador"
      render={r => r.status === "approved" ? (r.deliveryId || "--") : "--"}
    />
    <FunctionField render={record => record?.invoiceCnpj ? formatCnpj(record.invoiceCnpj) : 'Não informado'} label="CNPJ da nota" textAlign="center" />
    <ReferenceField
      label="Aprovado Por"
      source="approvedBy"
      basePath="company-employees"
      reference="company-employees"
      emptyText="--"
      link={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <SelectField
      label="Status"
      source="status"
      emptyText="--"
      choices={[
        { id: 'approved', name: 'Aprovado' },
        { id: 'reproved', name: 'Reprovado' }
      ]} />
  </Datagrid>
);

const ListFilters = () => {
  const notify = useNotify();

  const dateValidator = value => {
    if (moment(value).isValid()) {
      return false;
    }

    notify('Data inicial e final são obrigatórias.');
    return true;
  };

  return (
    <Filter>
      <FormDataConsumer alwaysOn>
        {({ formData }) => (
          <DateInput
            source="from"
            label="De"
            sx={{ marginBottom: 0.5 }}
            inputProps={{ max: formData.to || moment().format('YYYY-MM-DD') }}
            validate={dateValidator}
            required />
        )}
      </FormDataConsumer>
      <FormDataConsumer alwaysOn>
        {({ formData }) => (
          <DateInput
            source="to"
            label="Até"
            sx={{ marginBottom: 0.5 }}
            inputProps={{ min: formData.from, max: moment().format('YYYY-MM-DD') }}
            validate={dateValidator}
            required />
        )}
      </FormDataConsumer>
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.chain],
          children: (
            <ReferenceArrayInput
              source="stationIds"
              reference="stations"
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
              alwaysOn
            >
              <SelectArrayInput label="Postos" optionText="name" />
            </ReferenceArrayInput>
          )
        })
      }
      <ReferenceInput source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} allowEmpty alwaysOn>
        <SelectInput label="Produto" emptyText="Todos" optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="hasInvoice"
        label="Nota Fiscal"
        choices={[
          { id: true, name: 'Com nota' },
          { id: false, name: 'Sem nota' }
        ]}
        emptyText="Todos"
        allowEmpty
        alwaysOn
      />
    </Filter>
  );
}

const FillinInvoiceList = (props) => {

  const [selectedIds, setSelectedIds] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalFuelValues, setTotalFuelValues] = useState([]);
  const [selectFillins, setSelectFillins] = useState([]);
  const [xmlLoaded, setXmlLoaded] = useState({});
  const { data: fuels } = useGetList('fuels');

  const [invoiceFillins, setInvoiceFillins] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [openDialog, setModelDialog] = useState(false);

  const notify = useNotify();

  useEffect(() => {
    let totalAmount = 0;
    let totalValue = 0;
    let totalFuel = [];

    selectFillins.filter(fillin => selectedIds.includes(fillin.id)).forEach(fillin => {
      totalAmount += fillin.amount;
      totalValue += fillin.value;

      if (totalFuel.find(item => item.fuelId === fillin.fuelId)) {
        totalFuel = totalFuel.map(item => item.fuelId === fillin.fuelId ? { ...item, value: item.value + fillin.value } : item);
      } else {
        totalFuel = [...totalFuel, { fuelId: fillin.fuelId, value: fillin.value }];
      }
    });

    setTotalAmount(totalAmount);
    setTotalValue(totalValue);
    setTotalFuelValues(totalFuel);
  }, [selectFillins, selectedIds]);

  const sortCheckedItems = (checked, fillin) => {
    if (checked) {
      setSelectFillins(values => [...values, fillin]);
      setSelectedIds([...selectedIds, fillin.id]);
    } else {
      setSelectFillins(values => values.filter(value => value.id !== fillin.id));
      setSelectedIds(selectedIds.filter((itemId) => !(itemId === fillin.id)));
    }
  };

  const handleSelectAll = (checked, fillins) => {
    fillins = fillins.filter(fillin => !invoiceFillins.find(invoice => invoice.fillinIds.includes(fillin.id)));
    if (checked) {
      const fillinFilter = fillins.filter(fillin => {
        return !selectedIds.includes(fillin.id);
      });
      setSelectFillins(values => ([...values, ...fillinFilter]));
      setSelectedIds(ids => ([...ids, ...fillinFilter.map(fillin => fillin.id)]));
    } else {
      const fillinsIds = fillins.map(fillin => fillin.id);
      setSelectFillins(values => values.filter(value => !fillinsIds.includes(value.id)));
      setSelectedIds(ids => ids.filter(id => !fillinsIds.includes(id)));
    }
  }

  const handleConfirmInvoice = (invoice) => {
    if (!selectedIds.length) {
      notify('Nenhum abastecimento selecionado', { type: 'warning' });
      return;
    }

    if (!openDialog && isDifferenceSignificant(invoice.invoiceValue, totalValue)) {
      setModelDialog(true);
      return;
    }

    setInvoiceFillins(value => ([...value, { invoiceNumber: invoice.invoiceNumber, fillinIds: selectedIds }]));

    setModelDialog(false);
    setSelectedIds([]);
    setSelectFillins([]);
  }

  const resetInitialValues = (hardReset) => {
    setCurrentInvoice(null);
    setSelectedIds([]);
    setTotalAmount(0);
    setTotalValue(0);
    setTotalFuelValues([]);
    if (hardReset) {
      setSelectFillins([]);
      setXmlLoaded({});
    }
  }

  // useEffect(() => {
  //   if (currentInvoice) {
  //     setInvoiceFillins(value => ([
  //       ...value.filter(({ invoiceNumber }) => invoiceNumber !== currentInvoice.invoiceNumber),
  //       { invoiceNumber: currentInvoice.invoiceNumber, fillinIds: selectedIds }
  //     ]));
  //   }
  // }, [selectedIds]);

  useEffect(() => {
    const allFillinIds = invoiceFillins.reduce((acc, item) => ([...acc, ...(item.fillinIds || [])]), []);
    setSelectFillins(value => value?.filter(fillin => allFillinIds.includes(fillin.id)));
  }, [invoiceFillins]);

  return (
    <Permissions userRoles={[UserRole.admin, UserRole.chain, UserRole.station]}>
      <>
        <UploadInvoiceBulkAction
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          xmlLoaded={xmlLoaded}
          invoiceFillins={invoiceFillins}
          setInvoiceFillins={setInvoiceFillins}
          setXmlLoaded={setXmlLoaded}
          resetInitialValues={resetInitialValues}
          onReadyButton={handleConfirmInvoice}
          setCurrentInvoice={setCurrentInvoice}
          setModelDialog={setModelDialog}
          openDialog={openDialog}
          totalValue={totalValue}
        >
          {
            (xmlLoaded && xmlLoaded.cnpj) ?
              <List
                {...props}
                resource="fillins"
                title="Importar NF-e"
                filter={{ chainId: getChainId(), generateFinancialMovement: true }}
                actions={false}
                filters={<ListFilters />}
                filterDefaultValues={{
                  from: moment().startOf('day').subtract(1, 'month').toISOString(),
                  to: moment().endOf('day').toISOString(),
                  hasInvoice: false,
                  isAggregated: false,
                  invoiceCnpj: xmlLoaded.cnpj,
                  stationReviewStatus: FillinReviewStatus.approved,
                }}
                sort={{ field: 'receivedAt', order: 'DESC' }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: 'none',
                  }
                }}
                disablePrint
              >
                <TopListActions totalValue={totalValue} totalAmount={totalAmount} selectedIds={selectedIds} totalFuelValues={totalFuelValues} fuels={fuels} />
                <FillinDetailsDatagrid {...props} bulkActionButtons={false} selectedIds={selectedIds} sortCheckedItems={sortCheckedItems} handleSelectAll={handleSelectAll} invoiceFillins={invoiceFillins} currentInvoice={currentInvoice} />
              </List>
              :
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                <Typography variant="subtitle1" align="center" gutterBottom>Informe o arquivo XML para listar os abastecimentos</Typography>
              </Box>
          }
        </UploadInvoiceBulkAction>
      </>
    </Permissions>
  );
};

export default FillinInvoiceList;